import { ref, computed } from "@vue/composition-api";
import store from "@/store";

export default function useAsyncVerticalNavigation() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  //const appRole = userData.roles ? userData.roles[0] : "OPERATOR";
  const appRole = "OPERATOR";
  const navOrgList = computed(() => {
    let navBarOrg = localStorage.getItem("NavbarOrganization");
    let orgList = null;
    let navbarCountryList = [];
    let navbarWcList = [];
    if (navBarOrg) {
      orgList = JSON.parse(navBarOrg);
    } else {
      orgList = store.getters["pamis/GetNavOrgTree"];
    }
    if (orgList) {
      /*
      navbarCountryList.push({
        id: "nav_Countries",
        name: "Countries",
        title: "Countries",
        icon: "FlagIcon",
        children: orgList.map((x) => {
          return {
            id: `nav-country-${x.id}`,
            name: x.name,
            title: x.name,
            route: {
              name: "PamisCountry",
              params: { id: x.id, name: x.name },
            },
            visible: true,
          };
        }),
      });
      */
      for (let i in orgList) {
        if (orgList[i].sites) {
          let siteTab = -1;
          let countryWorkcenters = {
            id: `nav-country-${orgList[i].id}`,
            title: orgList[i].name,
            icon: "FlagIcon",
            children: [
              {
                id: `nav-country-overall-${orgList[i].id}`,
                title: "Overall",
                icon: "CircleIcon",
                route: {
                  name: "PamisCountry",
                  params: { id: orgList[i].id, name: orgList[i].name, tab: 0 },
                },
              },
              ...orgList[i].sites?.map((site) => {
                siteTab++;
                return {
                  id: `nav-site-${site.id}`,
                  title: site.name,
                  icon: "TrelloIcon",
                  children: [
                    {
                      level: 3,
                      icon: "CircleIcon",
                      id: `nav-site-overall-${site.id}`,
                      title: "Overall",
                      route: {
                        name: "PamisCountry",
                        params: {
                          id: orgList[i].id,
                          name: orgList[i].name,
                          tab: siteTab,
                        },
                      },
                    },
                    ...site.workCenters?.map((wc1) => {
                      wc1.level = 3;
                      return { ...getChildNode(wc1) };
                    }),
                  ],
                };
              }),
            ],
            visible: true,
          };
          navbarWcList.push(countryWorkcenters);
        }
      }
    }

    return [
      {
        title: "Home",
        route: "PamisHome",
        icon: "HomeIcon",
        visible: appRole === "CEO",
      },

      //...navbarCountryList,
      {
        title: "Dashboard",
        icon: "GridIcon",
        children: navbarWcList,
        visible: true,
      },
      //...navbarWcList,

      {
        id: "nav-Breakdown",
        title: "Downtimes",
        route: { name: "PamisBreakdown" },
        icon: "ActivityIcon",
        visible: true //appRole != "CEO",
      },
      {
        title: "Task Management",
        id: "nav-TaskManagement",
        icon: "ClipboardIcon",
        children: [
          {
            title: "Tasks",
            route: "PamisJobs",
            icon: "ActivityIcon",
            visible: true//appRole === "CEO",
          },
          {
            title: "Operators",
            route: "PamisOperatorJobs",
            icon: "ActivityIcon",
            visible: true//appRole === "CEO",
          },
        ],
        visible: true,
      },

      {
        id: "nav-Maintenance",
        title: "Maintenance",
        icon: "MonitorIcon",
        children: [
          {
            id: "nav-Maintenance",
            title: "Main Page",
            route: { name: "PredictiveMaintenance" },
            icon: "ActivityIcon",
            visible: true,
          },
          {
            id: "nav-maintenanceSettings",
            title: "Simulation",
            route: { name: "PredictiveMaintenanceSettings" },
            icon: "SettingsIcon",
            visible: true,
          },
        ],
        visible: true
      },
      {
        id: "nav-production-reports",
        title: "Production Reports",
        icon: "BarChartIcon",
        route: { name: "ProductionReports" },
        visible: true
      },

    ];
  });
  function getChildNode(child) {
    if (child.workCenters.length > 0) {
      return {
        level: child.level,
        id: `nav-workcenter-${child.id}`,
        name: child.name,
        title: child.name,
        icon: "LayersIcon",
        children: [
          {
            level: child.level + 1,
            icon: "CircleIcon",
            id: `nav-workcenter-overall-${child.id}`,
            title: "Overall",
            route: {
              name: "PamisWorkcenter",
              params: { id: child.id, name: child.name },
            },
          },
          ...child.workCenters?.map((childChild) => {
            childChild.level = child.level + 1;
            return getChildNode(childChild);
          }),
        ],
      };
    } else {
      return {
        level: child.level,
        id: `nav-workcenter-${child.id}`,
        name: child.name,
        title: child.name,
        icon: "CircleIcon",
        route: {
          name: "PamisWorkcenter",
          params: { id: child.id, name: child.name },
        },
      };
    }
  }

  return {
    navOrgList,
  };
}
